import React, { useState } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { VscLaw } from "react-icons/vsc";
import { LuDatabase } from "react-icons/lu";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { MdOutlineEnergySavingsLeaf, MdOutlineGavel, MdLightbulbOutline } from 'react-icons/md';
import { FaBitcoin } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa";
import { AiOutlineFileProtect } from "react-icons/ai";

const SectionService = ({ contents }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ name: '', detail: '', content: null });

  const handleShow = (item) => {
    setModalContent(item);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const mockup = [
    {
      name: 'Corporate and M&A',
      detail: `Through our adeptness in corporate and M&A legal services, we aid clients in maneuvering intricate transactions, mitigating risks, and optimizing corporate value. Our comprehensive support spans all facets of corporate dealings, ensuring a holistic approach to legal services in mergers and acquisitions. Notably, our profound experience shines through in M&A engagements within the energy sector, where we navigate specialized regulations imposed by the energy regulator, showcasing our proficiency in this domain.`,
      content: <>
        <b className='mb-2 d-block'>Our team stands prepared to offer an array of services encompassing:</b>
        <ul>
          <li>Compliance and Regulatory Affairs</li>
          <li>Corporate Governance Expertise</li>
          <li>Corporate Restructuring and Organizational Optimization</li>
          <li>Entity Establishment and Structuring Assistance</li>
          <li>Legal Counsel on Joint Ventures and Strategic Partnerships</li>
          <li>Post-Merger Integration Support</li>
          <li>Preparation of M&A Applications and Market Analysis</li>
          <li>Drafting and Negotiating Shareholder Agreements</li>
        </ul>
      </>,
      row_value: <FaRegHandshake size={100} className='mb-4' />
    },
    {
      name: 'Data & Technology',
      detail: `Our legal services encompass a comprehensive range of data and business operations. We guide our clients in adhering to data privacy laws and regulations, notably Thailand's Personal Data Protection Act 2019 ("PDPA") and the European Union's General Data Protection Regulation (GDPR). We safeguard our clients' technology assets through patents, trademarks, copyrights, and trade secrets. Our adept team excels in drafting and negotiating contracts for technology licensing, software development, cloud services, and other tech-related agreements, offering counsel on contract disputes and vendor relationships. Additionally, we provide support in addressing legal issues arising from digital transformation initiatives, such as AI implementation, IoT technologies, blockchain innovations, and cloud computing strategies.`,
      content: <>
        <b className='mb-2 d-block'>Our team stands prepared to offer an array of services encompassing:</b>
        <ul>
          <li>Preparation and negotiation of contracts for technology transactions</li>
          <li>Guidance on data privacy and protection measures</li>
          <li>Protection of intellectual property rights</li>
          <li>Legal solutions and compliance strategies for digital transformation initiatives</li>
          <li>Legal solutions and compliance measures for cybersecurity concerns</li>
        </ul>
      </>,
      row_value: <LuDatabase size={100} className='mb-4' />
    },
    {
      name: 'Dispute Resolution and Arbitration',
      detail: `We counsel clients on strategies to address administrative, constitutional, or other risk-laden scenarios. Furthermore, we play a pivotal role in remedying such situations, whether it involves spearheading discussions with pertinent authorities, securing and safeguarding legal wins, or pursuing alternate dispute resolution methods like mediation or arbitration. By devising and implementing tailored strategies for every case, we aid private enterprises in safeguarding their business concerns.`,
      content: <>
        <b className='mb-2 d-block'>Our team stands prepared to offer an array of services encompassing:</b>
        <ul>
          <li>Drafting arbitration clauses and managing arbitration proceedings</li>
          <li>Conducting thorough dispute analysis</li>
          <li>Enforcing arbitral awards</li>
          <li>Facilitating mediation and settlement processes</li>
          <li>Developing strategic approaches</li>
        </ul>
      </>,
      row_value: <MdOutlineGavel size={100} className='mb-4' />
    },
    {
      name: 'Intellectual Property',
      detail: `Through our extensive range of intellectual property (IP) legal services, we aid clients in safeguarding their innovations, trademarks, and artistic creations while maximizing the worth of their IP assets. Our expert guidance includes evaluating patentability, analyzing the novelty of inventions, and ascertaining the viability of patent registration. Furthermore, we support clients in crafting patent applications, encompassing claims and specifications, and navigating the intricate process of filing with pertinent patent state authorities`,
      content: <>
        <b className='mb-2 d-block'>Our team stands prepared to offer an array of services encompassing:</b>
        <ul>
          <li>Intellectual Property (IP) Due Diligence</li>
          <li>Intellectual Property (IP) Litigation</li>
          <li>Intellectual Property (IP) Portfolio Management</li>
          <li>Intellectual Property (IP) Registration and Prosecution</li>
          <li>Licensing and Technology Transfer</li>
          <li>Protection of Trade Secrets</li>
        </ul>
      </>,
      row_value: <MdLightbulbOutline size={100} className='mb-4' />
    },
    {
      name: 'Digital Assets',
      detail: `Our legal team pioneers as the foremost legal practitioners in Thailand with a recognized international digital asset presence. Catering to multinational clients, we offer comprehensive business and legal services across various digital asset products. Additionally, we specialize in crafting and structuring legal frameworks between entities, ensuring adherence to pertinent IT regulations in Thailand, including the Personal Data Protection Act (PDPA), E-payment regulations, Anti-Money Laundering (AML) measures, and cybersecurity protocols.`,
      content: <>
        <b className='mb-2 d-block'>Our team stands prepared to offer an array of services encompassing:</b>
        <ul>
          <li>Advisory services for digital assets</li>
          <li>Brokerage services for digital assets</li>
          <li>Custodial wallet provision for digital assets</li>
          <li>Trading services as a digital asset dealer</li>
          <li>Operating a digital asset exchange platform</li>
          <li>Management of digital asset funds</li>
          <li>Issuance services for ICOs or digital assets</li>
          <li>Management services for an ICO portal</li>
        </ul>
      </>,
      row_value: <AiOutlineFileProtect size={100} className='mb-4' />
    },
    {
      name: 'Energy and Infrastructure Projects',
      detail: `By providing tailored legal services for energy projects and infrastructure development, we assist clients in navigating legal challenges, minimizing risks, and ensuring successful project outcomes. Our legal team collaborates closely with architects, engineers, and financial analysts to prepare a feasibility study report for Public-Private Partnership (PPP) projects. We conduct legal research, create license checklists for energy project development, and negotiate contracts for energy transactions, including onsite and virtual private power purchase agreements`,
      content: <>
        <b className='mb-2 d-block'>Our team stands prepared to offer an array of services encompassing:</b>
        <ul>
          <li>Preparation of Feasibility Reports for PPP Projects</li>
          <li>Assistance with Land Acquisition and Real Estate matters</li>
          <li>Legal Advice on Project Financing</li>
          <li>Market Sounding for PPP Projects</li>
          <li>Drafting and Negotiation of Power Purchase Agreements</li>
          <li>Drafting and Negotiation of PPP Contracts</li>
          <li>Drafting and Negotiation of Wheeling Service Agreements</li>
        </ul>
      </>,
      row_value: <MdOutlineEnergySavingsLeaf size={100} className='mb-4' />
    },
  ];

  return (
    <>
      <section id="service">
        <Container>
          <Row>
            <Col>
              <h1 className='font-header mb-4'>Expertise</h1>
            </Col>
          </Row>
          <Row>
            {mockup.map((item, index) => (
              <Col md={4} key={index} className={``}>
                <div className={`p-5 ${index === 0 ? 'bg-color-primary text-white' : ''}`}>
                  {item.row_value}
                  <h4 className='font-header'>{item.name}</h4>
                  <hr className={`${index === 0 ? 'border border-bottom-0 border-white opacity-100' : 'border border-bottom-0 border-black opacity-100'}`} />
                  <p className='line-clamp-3'>{item.detail}</p>
                  <Button
                    className={`p-0 bg-transparent border-0 text-decoration-none fw-bold ${index === 0 ? 'text-white' : 'text-black'}`}
                    onClick={() => handleShow(item)}
                  >
                    Read More <IoIosArrowDroprightCircle size={20} className='ms-1 mb-1' />
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Modal */}
      <Modal show={showModal} size='lg' onHide={handleClose} className="modal-fullscreen">
        <Modal.Header className='px-4' closeButton>
          <Modal.Title>{modalContent.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <p>{modalContent.detail}</p>
          {modalContent.content}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

SectionService.defaultProps = {
  contents: [],
};

export default SectionService;
