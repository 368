import React, { useState, useEffect } from 'react';
import { Carousel, Row, Col } from 'react-bootstrap';
import { storageUrl } from '../Constants/Global';

const MultiItemCarousel = ({ items, sm, md, lg }) => {
  console.log("MultiItemCarousel :", items);

  const [itemsPerSlide, setItemsPerSlide] = useState(lg);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setItemsPerSlide(sm);
      } else if (window.innerWidth <= 992) {
        setItemsPerSlide(md);
      } else {
        setItemsPerSlide(lg);
      }
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sm, md, lg]);

  const slides = [];
  for (let i = 0; i < items.length; i += itemsPerSlide) {
    slides.push(items.slice(i, i + itemsPerSlide));
  }

  return (
    <Carousel className='course-slide'>
      {slides.map((slide, index) => (
        <Carousel.Item key={index}>
          <Row>
            {slide.map((item, idx) => (
              <Col key={idx} xs={12 / sm} md={12 / md} lg={12 / lg}>
                <div className="carousel-item-content">
                  <div
                    className='d-block w-100 bg-img ratio-1x1'
                    style={{ backgroundImage: `url(${storageUrl}/media/${item.set1})`, backgroundPosition: 'center center', backgroundSize: 'contain', backgroundColor: '#fff' }}
                  ></div>
                </div>
              </Col>
            ))}
          </Row>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default MultiItemCarousel;
