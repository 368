import React, { useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import ContentHTML from './ContentHTML';

function SectionVision({ contents, topic }) {
  const mockup = {
    items: [
      {
        row_value: '',
        set1: 'Litigation',
        set2: 'Litigation including criminal as well as civil litigation lawsuits, labor lawsuits, and international arbitration.'
      }
    ]
  };

  return (
    <section id="vision" className='bg-color-primary'>
      <Container fluid>
        <Row>
          {contents?.map((item, index) => (
            <>
              <Col xs={12} md={6} className="text-center px-0">
                <div className='bg-img w-100 h-100' style={{ backgroundImage: `url(${urlApi}/storage/media/${item.set3})`, minHeight: '300px' }}></div>
              </Col>
              <Col xs={12} md={5} className='px-0 px-md-5'>
                <div className='p-5 px-5 px-md-5 text-white'>
                  <h1 className='font-header mt-5 mb-5'><span className='font-header tx-color-secondary'>Our</span> Vision</h1>
                  <ContentHTML htmlContent={item.set2} />
                </div>
              </Col>
            </>
          ))}
        </Row>
      </Container>
    </section >
  )
}

SectionVision.defaultProps = {
  contents: [],
  topic: []
}

export default SectionVision