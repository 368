import React, { useState } from 'react';
import { Carousel, Button } from 'react-bootstrap';
import slide1 from '../Medias/Photos/slide1.png';

const SectionCarousel = ({ contents }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const mockup = [
    {
      row_value: slide1,
      detail:
        <>
          <h1>PT & Associates</h1>
          <h3>Creative Legal Strategies</h3>
          <p>Our law firm is known for delivering outcome-focused legal services to governmental agencies, corporate entities, and individual clients. We are dedicated to comprehensively grasping our clients' legal and business needs, offering top-notch services, and devising inventive solutions geared towards results.</p>
        </>
    }
  ];

  return (
    <>
      <section id="home">
        <Carousel activeIndex={index} onSelect={handleSelect}>
          {mockup.map((item, index) => (
            <Carousel.Item key={index} className="rps-carousel bg-img" style={{ backgroundImage: 'url(' + item.row_value + ')' }}>
              <div className="position-absolute w-100 h-100" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                <Carousel.Caption className="top-50 start-50 translate-middle text-start px-0 px-md-5">
                  {item.detail}
                  <Button size='lg' className='bg-color-primary border-0 me-3 mt-3 p-1 p-md-3 px-2 px-md-5'>Our Services</Button>
                  <Button size='lg' className='bg-white tx-color-secondary border-0 me-3 mt-3 p-1 p-md-3 px-2 px-md-5'>Contact Us</Button>
                </Carousel.Caption>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </section>
    </>
  )
}

SectionCarousel.defaultProps = {
  contents: []
}

export default SectionCarousel