import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Offcanvas, Container } from 'react-bootstrap';
import Logo from '../Medias/Logos/logo_h.png';

const MainMenu = ({ contents }) => {
  const expand = 'lg';
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const openOffcanvas = () => {
    console.log("openOffcanvas");
    setShowOffcanvas(true);
  }

  const closeOffcanvas = () => {
    console.log("closeOffcanvas");
    setShowOffcanvas(false);
  }

  const scrollToTarget = (v_target) => {
    var target = document.getElementById(v_target);
    window.scrollTo({
      top: target.offsetTop - 125,
      behavior: 'smooth'
    });
    closeOffcanvas();
  }

  return (
    <>
      <Navbar expand={expand} id="main-menu" sticky="top" bg='white'>
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={Logo} className="logo mx-3" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={openOffcanvas} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            show={showOffcanvas}
            onHide={closeOffcanvas}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img src={Logo} className="logo mx-3" alt="Logo" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link onClick={() => scrollToTarget('home')} className="fs-5 text-black px-2">Home</Nav.Link>
                <Nav.Link onClick={() => scrollToTarget('about')} className="fs-5 text-black px-2">About Us</Nav.Link>
                <Nav.Link onClick={() => scrollToTarget('service')} className="fs-5 text-black px-2">Expertise</Nav.Link>
                <Nav.Link onClick={() => scrollToTarget('client')} className="fs-5 text-black px-2">Clients</Nav.Link>
                <Nav.Link onClick={() => scrollToTarget('team')} className="fs-5 text-black px-2">Team</Nav.Link>
                <Nav.Link onClick={() => scrollToTarget('knowledge')} className="fs-5 text-black px-2">Knowledge</Nav.Link>
                <Nav.Link onClick={() => scrollToTarget('article')} className="fs-5 text-black px-2">News & Events</Nav.Link>
                <Nav.Link onClick={() => scrollToTarget('contact')} className="fs-5 text-black px-2">Contact</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}

MainMenu.defaultProps = {
  contents: {
    routePath: {
      currentPath: '/'
    }
  }
}

export default MainMenu