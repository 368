import React, { useState } from 'react';
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import NoPhoto from '../Medias/LibImages/no-image.svg';

import Photo1 from '../Medias/Photos/1657282225_0.jpg';
import Photo2 from '../Medias/Photos/Pic1.jpg';
import Photo3 from '../Medias/Photos/1711909790_0.png';
import Photo4 from '../Medias/Photos/1714240910_0.jpg';
import Photo5 from '../Medias/Photos/im1.jpg';
import Photo6 from '../Medias/Photos/news_20240819.jpg';

function SectionNews({ contents }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const mockup = [
    {
      name: `On August 16, 2024, Mr. Nontnawat Wattananont conducted a legal workshop focused on creating regulatory frameworks to limit the manufacturing and use of single-use plastic products, while also promoting eco-design for plastic packaging requirements in Thailand. His expertise in circular economy promotion law contributes significantly to the project titled "Marine Litter Prevention through Reduction, Sustainable Design, and Recycling of Plastic Packaging (MA-RE-DESIGN)."`,
      row_slug: '',
      row_value: Photo6
    },
    {
      name: `The Office of the Personal Data Protection Commission (PDPC) conducted an interactive seminar on 24th and 27th of May 2024, for PDPA/DPO trainers. A primary goal of this seminar was to equip trainers to consistently and accurately impart knowledge and practices regarding personal data protection and data security. Participants who actively engaged in this seminar and met the requirements will be recognized by the PDPC as certified PDPA and DPO trainers.`,
      row_slug: '',
      row_value: Photo2
    },
    {
      name: `On the 5th of July, Dr. Tanatthep Tianprasit, our Partner, joined a public seminar re: "Marketing in the Data Privacy Era". The event was jointly organized by SB Telecom (Thailand), D-Serve Consulting, ADA Thailand, and Treasure Data. We are delighted to use this opportunity to share a hard copy version of our recent pubication -" Q&A Book on PDPA for Practioners" (first edition) to Miss Nunramon Tangchitaree, Chief Executive Officer, D-Serve Consulting.`,
      row_slug: '',
      row_value: Photo1
    },
    {
      name: `PT & Associates provided guidance to Super Energy Group Public Co. Ltd. (SEG) regarding its merger and acquisition proposal, which was submitted to the Energy Regulatory Commission (ERC) in accordance with the Energy Industry Act 2007 and the relevant ERC Notifications and Guidelines. The ERC approved the proposal on 21st of February 2024. According to the ERC, this transaction will support SEG and its subsidiaries in their investment and strategic endeavors. Importantly, it was found that this merger will not impede competition in electricity generation markets nor create barriers to entry for other energy operators.`,
      row_slug: '',
      row_value: Photo3
    },
    {
      name: `On the 24th of April 2024, our team had a very unique opportunity to discuss and share our legal analysis on a Low Carbon Destination Island project with Koh Samui City Municipality Office. This exciting project involves distributed green electricity generation, sustainable water supply, and circular economy business models.`,
      row_slug: '',
      row_value: Photo4
    },
    {
      name: `On June 18, 2024, PT & Associates, along with a team of city planning and development experts, supported the Tobacco Authority of Thailand (TAT) in orchestrating a market sounding event. The purpose was to gauge the interest of investors and developers in renting TAT's lands in Bangkok and Chiang Rai. Our role encompassed presenting proposals concerning contractual frameworks for land use and rental fees. This event signifies a shift in TAT's business model, transitioning from solely being a tobacco producer to becoming a land lessor, thus signaling its entry into the real estate market.`,
      row_slug: '',
      row_value: Photo5
    },
  ];

  const handleViewContent = (name, content, image) => {
    setSelectedContent(content);
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedContent(null);
    setSelectedImage(null);
  };

  return (
    <section id="article" className="pt-5 pb-0">
      <Container className='pt-5 pb-0'>
        <h1 className='font-header text-center mb-5'>News & Content</h1>
        <Row>
          {mockup.map((item, index) => (
            <Col sm={12} md={4} key={index}>
              <Card className='w-100 border-0 rounded-0 mb-5'>
                <div className="bg-img ratio-16x9" style={{ backgroundImage: `url(${item.row_value})` }}></div>
                <Card.Title className='font-default w-100 p-3 px-4 m-0 fw-normal tx-color-primary'>
                  <button
                    className='btn tx-color-primary text-decoration-none text-start p-0'
                    onClick={() => handleViewContent(item.name, item.name, item.row_value)}
                  >
                    <p className='line-clamp-3'>{item.name}</p>
                  </button>
                </Card.Title>
              </Card>
            </Col>
          ))}
        </Row>
        {/* <Row className='mt-5'>
          <Col className='text-center'>
            <Button className='font-header bg-color-secondary border-0 text-white px-4 fs-4'>Show More</Button>
          </Col>
        </Row> */}

        {/* Modal for Content */}
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Body className='p-4'>
            {/* <img src={selectedImage} alt="News" className="w-100 mb-3" style={{ maxHeight: '400px', objectFit: 'cover' }} /> */}
            <img src={selectedImage} alt="News" className="w-100 mb-3" />
            <p>{selectedContent}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </section>
  );
}

SectionNews.defaultProps = {
  contents: []
};

export default SectionNews;
