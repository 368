import React, { useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';

function SectionPractice({ contents, topic }) {
  const mockup = {
    items: [
      {
        row_value: '',
        set1: 'Litigation',
        set2: 'Litigation including criminal as well as civil litigation lawsuits, labor lawsuits, and international arbitration.'
      }
    ]
  };

  return (
    <section id="practice" className="pt-5 pb-0">
      <Container className='pt-5 pb-5'>
        <h1 className='font-header text-center mb-5'><span className='font-header tx-color-secondary'>Practice </span>Areas</h1>
        <Row>
          {contents?.map((item, index) => (
            <Col xs={12} md={4} className="text-center px-5 mb-5">
              <img src={`${urlApi}/storage/media/${item.set3}`} style={{ maxWidth: '160px' }} className='mb-4' />
              <h3 className='font-default mb-2'>{item.set1}</h3>
              <span>{item.set2}</span>
            </Col>
          ))}
        </Row>
      </Container>
    </section >
  )
}

SectionPractice.defaultProps = {
  contents: [],
  topic: []
}

export default SectionPractice