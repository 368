import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FormContact from '../Components/FormContact';
import ObjSocialList from './ObjSocialList';

const SectionContact = ({ contents }) => {
  return (
    <>
      <section id="contact" className="pt-5 pb-5 mt-5 mb-5">
        <Container fluid>
          <Row>
            <Col md={12} lg={6} className="p-0 px-2 px-md-0">
              {/* <iframe src={contents?.contact?.["google-maps"]} width="100%" height="500" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="googlemaps"></iframe> */}
              <iframe src={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3876.2894986433735!2d100.6417121043015!3d13.700908237052875!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d60136eb835a7%3A0x7f480351ecce9be4!2z4Lir4Lih4Li54LmI4Lia4LmJ4Liy4LiZ4Lih4Li04LiV4Lij4Lig4Liy4LieIDIg4LmB4LiC4Lin4LiH4Lir4LiZ4Lit4LiH4Lia4Lit4LiZIOC5gOC4guC4lSDguJvguKPguLDguYDguKfguKgg4LiB4Lij4Li44LiH4LmA4LiX4Lie4Lih4Lir4Liy4LiZ4LiE4LijIDEwMjUw!5e0!3m2!1sth!2sth!4v1718820682352!5m2!1sth!2sth"} width="100%" height="500" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="googlemaps"></iframe>
            </Col>
            <Col md={12} lg={5} className="px-2 px-md-5 pt-4">
              <h3 className="font-header tx-color-secondary mb-3">Contact Us</h3>
              <p className='tx-color-secondary mb-4'>We'd love to hear from you! Whether you have a question, feedback, or just want to say hello, feel free to get in touch with us.</p>
              <FormContact lang={'en'} />
            </Col>  
          </Row>
        </Container>
      </section>
    </>
  )
}

export default SectionContact