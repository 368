import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../Medias/Logos/logo_h-wt.png';
import ObjSocialList from './ObjSocialList';

const MainFooter = ({ contents }) => {
  const scrollToTarget = (v_target) => {
    var target = document.getElementById(v_target);
    window.scrollTo({
      top: target.offsetTop - 125,
      behavior: 'smooth'
    });
  }

  return (
    <footer id="footer" className="pt-5 pb-2 bg-color-secondary text-white">
      <Container>
        <Row>
          <Col md={4} className='mb-4'>
            <img src={Logo} alt="Logo" className="logo mb-4" />
            <h5>INNOVATIVE LEGAL SOLUTIONS</h5>
            <p>We are a law firm with a reputation for providing result-driven legal services
              to governmental agencies, corporate and individual clients. We are committed
              to understanding our clients’ legal and business requirements by providing
              excellent services and finding innovative result-oriented solutions.</p>
          </Col>
          <Col md={3} className='ps-md-5 mb-4'>
            <h5>Menu</h5>
            <ul className='p-0 m-0' style={{ "listStyleType": "none" }}>
              <li>
                <Nav.Link onClick={() => scrollToTarget('about')} className="text-white text-decoration-none">About Us</Nav.Link>
              </li>
              <li>
                <Nav.Link onClick={() => scrollToTarget('service')} className="text-white text-decoration-none">Expertise</Nav.Link>
              </li>
              <li>
                <Nav.Link onClick={() => scrollToTarget('client')} className="text-white text-decoration-none">Clients</Nav.Link>
              </li>
              <li>
                <Nav.Link onClick={() => scrollToTarget('team')} className="text-white text-decoration-none">Team</Nav.Link>
              </li>
              <li>
                <Nav.Link onClick={() => scrollToTarget('knowledge')} className="text-white text-decoration-none">Knowledge</Nav.Link>
              </li>
              <li>
                <Nav.Link onClick={() => scrollToTarget('article')} className="text-white text-decoration-none">News & Event</Nav.Link>
              </li>
              <li>
                <Nav.Link onClick={() => scrollToTarget('contact')} className="text-white text-decoration-none">Contact</Nav.Link>
              </li>
            </ul>
          </Col>
          <Col md={5}>
            <h5>Contact us</h5>
            <Row>
              <Col>
                <h6>Address</h6>
                209 Soi Muban Mittraphap2, Nong Bon, Prawet, Bangkok 10250
              </Col>
              <Col>
                <h6>Email</h6>
                info@pt-assoc.com
                <h6 className='mt-4'>Phone</h6>
                086-026-1944
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col className="text-center text-white">&copy;2024 PT & Associates, All Rights Reserved</Col>
        </Row>
      </Container>
    </footer>
  )
}

MainFooter.defaultProps = {
  contents: {
    routePath: {
      currentPath: '/'
    }
  }
}

export default MainFooter