import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import Logo1 from '../Medias/Photos/1649439336_0.png';
import Logo2 from '../Medias/Photos/1649439336_4.png';
import Logo3 from '../Medias/Photos/1649439336_1.png';
import Logo4 from '../Medias/Photos/1649439336_2.png';
import Logo5 from '../Medias/Photos/1654160149_0.png';
import Logo6 from '../Medias/Photos/1654160162_0.png';
import Logo7 from '../Medias/Photos/1701186441_0.png';
import Logo8 from '../Medias/Photos/client2.jpg';
import Logo9 from '../Medias/Photos/client3.jpg';
import Logo10 from '../Medias/Photos/spcg-logo.png';
import Logo11 from '../Medias/Photos/ION_crop.png';
import MultiItemCarousel from './MultiItemCarousel';

const SectionClientCarousel = ({ contents }) => {
  const mockup = [
    { logo: Logo1 },
    { logo: Logo2 },
    { logo: Logo3 },
    { logo: Logo4 },
    { logo: Logo5 },
    { logo: Logo6 },
    { logo: Logo7 },
    { logo: Logo8 },
    { logo: Logo9 },
    { logo: Logo10 },
    { logo: Logo11 },
  ];

  return (
    <section id="client">
      <Container className='mt-5 mb-5 pt-5 pb-5'>
        <h1 className='font-header text-center mb-4'>Our Clients</h1>
        <MultiItemCarousel items={contents} sm={3} md={6} lg={6} />
      </Container>
    </section>
  );
};

SectionClientCarousel.defaultProps = {
  contents: []
};

export default SectionClientCarousel;
