import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Photo1 from '../Medias/Photos/Pic3.jpg';
import Photo2 from '../Medias/Photos/Pic2.jpg';

const SectionAbout = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <section id="about" className='pt-5 pb-5'>
      <Container className='pt-5 pb-5'>
        <Row>
          <Col sm={12} md={6}>
            <img src={Photo1} className='w-100' />
          </Col>
          <Col sm={12} md={6} className='pb-5 ps-5 pe-5'>
            <div className='pb-5 pt-5'>
              <h3 className='font-header mb-3'>About Us</h3>
              <p>Established in 2015 under the name "PJT Law," our office started as a "Legal Think Tank," specializing in in-depth legal research on various contemporary Thai law topics, particularly constitutional and human rights issues, for an international clientele. We earned the trust of the Civil Aviation Authority of Thailand for translating aviation laws and regulations for submission to the International Civil Aviation Organization (ICAO). With the expansion of our team to include partners with diverse expertise, we rebranded as "PT & Associates" in 2021.</p>
              <p>At PT and Associates, we specialize in Corporate and M&A, Data & Technology, Dispute Resolution and Arbitration, Intellectual Property, Digital Assets, and Project and Infrastructure. Our firm prides itself on expertise, professionalism, integrity, and unwavering dedication to our clients' needs. Comprising legal practitioners with tailored expertise across a spectrum of legal domains, we offer excellent communication, and an unwavering commitment to delivering top-notch legal services.</p>
              <Button variant='' color='color-primary' className='bg-color-primary text-white border-0 px-4' onClick={handleShow}>Read More</Button>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal */}
      <Modal show={showModal} size='lg' onHide={handleClose} className="modal-fullscreen" >
        <Modal.Header className='px-4' closeButton>
          <Modal.Title>About Us</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <Row>
            <Col>
              <img src={Photo2} className='w-100' />
            </Col>
            <Col>
              <h5 className='mb-4'>Giving back</h5>
              <p>We firmly believe that a law firm's role extends beyond offering legal services for fair compensation; it should harness its legal knowledge to positively impact society. At PT & Associates, we empower our lawyers to impart their expertise by lecturing at law schools and hosting complimentary public seminars. Additionally, we frequently provide scholarships to law students for their participation in legal training and seminars, fostering a spirit of learning and collaboration within the legal community.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className='mt-4'>“Participating in the AIEN Energy Summit on the topic of Energy Evolution, this event featured lectures and workshops by experienced speakers and professionals with expertise and practical experience in the field of energy from various countries. In the workshops attended, topics included 'The Evolving Energy and Petroleum Laws of Thailand, Vietnam, Malaysia, and China: A Comparative Overview of the Changing Regulatory Landscape' and 'Optimizing the AIEN Model LNG Sale and Purchase Agreement with Mock Negotiation.' In the first workshop attended, discussions revolved around energy activities in each country including the legal frameworks, and interactive sessions with experienced participants led to practical insights and valuable advice for applying knowledge in real-world scenarios. The second workshop focused on key aspects of LNG sale and purchase agreements, including negotiation processes and agreement terms, while discussions with participants from Indonesia provided insights into their energy activities and structures.</p>
              <p>Participation in this event left a strong impression as it allowed me to expand my knowledge beyond what I had studied in business law seminars and investment laws in ASEAN countries, particularly in the field of energy laws and practical applications. The majority of participants were experienced professionals, enabling me to engage in discussions about real-world practices, gain insights to enhance existing knowledge, express opinions, and receive constructive feedback. This experience highlighted areas for personal and professional development in the future, and I extend my gratitude to PT & Associates for their support in enabling my participation in this event.”</p>
              <h5 className='mt-4 text-end fst-italic fw-bold'>Maethawee  Sangwimarn</h5>
              <h6 className='mt-1 text-end'>Law student, Faculty of Law, Chulalongkorn University</h6>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default SectionAbout;
