import React from 'react';
import { Carousel, Container } from 'react-bootstrap';

const testimonials = [
  {
    quote: "Preparation and negotiation of a long-term business collaboration between a Thai jewelry manufacturer and an international",
    name: "Jewelry Company",
  },
  {
    quote: "Advice on corporate and shareholding structure",
    name: "American shoe manufacturer for establishment of factory in Thailand",
  }
];

const SectionTestimonial = () => {
  return (
    <section id="testimonial" className='pb-5'>
      <Container className="mb-5">
        <Carousel variant='dark' indicators="bottom">
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex flex-column align-items-center text-center w-50 mx-auto">
                <p className="lead">{testimonial.quote}</p>
                <h5>{testimonial.name}</h5>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </section>
  );
};

export default SectionTestimonial;
