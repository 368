import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import NoPhoto from '../Medias/Photos/1655820470_0.webp';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Photo1 from '../Medias/Photos/client1.png';
import Public1 from '../Medias/Photos/1672481410_0.jpg';
import File1 from '../Medias/Photos/1672481281_0.pdf';
import Public2 from '../Medias/Docs/power-of-the-act-cover.png';
import File2 from '../Medias/Docs/power-of-the-act.pdf';

const SectionPublication = ({ contents }) => {
  // console.log('Load Publication :', contents);

  const [visibleItems, setVisibleItems] = useState(6);

  const loadMoreItems = () => {
    setVisibleItems(prevVisibleItems => prevVisibleItems + 6);
  };

  const mockup = [
    // {
    //   name: 'Power of The Act: เมืองอัจฉริยะและโอกาสทางธุรกิจสำหรับนักพัฒนาเมือง',
    //   tags: 'Power of The Act',
    //   row_value: Photo1
    // },
    // {
    //   name: 'Power of The Act: เมืองอัจฉริยะและโอกาสทางธุรกิจสำหรับนักพัฒนาเมือง',
    //   tags: 'Power of The Act',
    //   row_value: NoPhoto
    // },
    // {
    //   name: 'Power of The Act: เมืองอัจฉริยะและโอกาสทางธุรกิจสำหรับนักพัฒนาเมือง',
    //   tags: 'Power of The Act',
    //   row_value: NoPhoto
    // },
    // {
    //   name: 'Power of The Act: เมืองอัจฉริยะและโอกาสทางธุรกิจสำหรับนักพัฒนาเมือง',
    //   tags: 'Power of The Act',
    //   row_value: NoPhoto
    // },
    // {
    //   name: 'Power of The Act: เมืองอัจฉริยะและโอกาสทางธุรกิจสำหรับนักพัฒนาเมือง',
    //   tags: 'Power of The Act',
    //   row_value: NoPhoto
    // },
    // {
    //   name: 'Power of The Act: เมืองอัจฉริยะและโอกาสทางธุรกิจสำหรับนักพัฒนาเมือง',
    //   tags: 'Power of The Act',
    //   row_value: NoPhoto
    // },
  ];

  const mockup2 = [
    {
      name: 'รวมบทความ Power of the Act',
      tags: 'Publications',
      row_value: Public2,
      row_file: File2
    },
    {
      name: 'รวมบทความ Power of the Act 2022',
      tags: 'Publications',
      row_value: Public1,
      row_file: File1
    },
  ];

  return (
    <>
      <section id="knowledge" className='pt-5 pb-5'>
        <Container className='pt-5 pb-5'>
          <h1 className="font-header tx-color-secondary text-center mb-5">Knowledge</h1>

          {/* <Row className='mb-5'>
            <Col className='text-center' md={{ span: 6, offset: 3 }}>
              <img src={Photo1} style={{ width: '80%', maxWidth: '200px' }} className='mb-3' />
              <p>We have obtained permission from the media intelligence and news agency, InfoQuest, to republish semi-academic articles focusing on emerging trends in energy business, policy, and laws. These articles will be featured in a dedicated column titled "Power of the Act" on InfoQuest's website.</p>
            </Col>
          </Row>
          <Row>
            {contents
              .filter(item => item.set3 === '1' && item.set4 === '6')
              .slice(0, visibleItems)
              .map((item, index) => (
                <Col md={4} key={index} className='px-4'>
                  <Card className='rounded-0 shadow border-0 mb-5'>
                    <Card.Img
                      className='ratio-16x9 bg-img bg-white rounded-0'
                      style={{ backgroundImage: `url(${urlApi}/storage/media/${item.set5})` }}
                    />
                    <Card.Body>
                      <h5 className='tx-color-secondary text-uppercase mb-1'>{item.set1}</h5>
                      <h6 className='mt-3 mb-1 tx-color-primary'>
                        {item.tags}
                        <a href={item.set6} className='tx-color-primary' target='_blank'>
                          <span className='float-end'><MdKeyboardDoubleArrowRight /> Read More</span>
                        </a>
                      </h6>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
          {visibleItems < contents.filter(item => item.set3 === '1' && item.set4 === '6').length && (
            <Row>
              <Col className='text-center mb-5'>
                <Button size='lg' className='px-4 bg-color-primary border-0' onClick={loadMoreItems}>Read More</Button>
              </Col>
            </Row>
          )} */}
          <Row className='mt-5 mb-5'>
            <Col className='text-center' md={{ span: 6, offset: 3 }}>
              <h3>Publications</h3>
              <p>We firmly believe in the power of knowledge. Therefore, we consistently extend support, whether through financial assistance or academic contributions, to legal publications.</p>
            </Col>
          </Row>
          <Row>
            {mockup2.map((item, index) => (
              <Col md={3} key={index} className='px-4 mx-auto'>
                <Card className='rounded-0 shadow border-0 mb-5'>
                  <Card.Img className='rounded-0' src={item.row_value} />
                  <Card.Body>
                    <h5 className='tx-color-secondary text-uppercase mb-1'>{item.name}</h5>
                    <h6 className='mt-3 mb-1 tx-color-primary'>
                      {item.tags}
                      <a href={item.row_file} className='tx-color-primary' target='_blank'>
                        <span className='float-end'><MdKeyboardDoubleArrowRight /> Read More</span>
                      </a>
                    </h6>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}

SectionPublication.defaultProps = {
  contents: []
}

export default SectionPublication