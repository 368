// PRD = /api/2.0
// PRD in subfolder = ../api/2.0
// DEV = http://envphp:8080/api/2.0
// PRD = api/2.0
// CALL PRD = http://www.domain.com/api/2.0
// CALL PRD HTTPS = https://www.domain.com/api/2.0
// const urlApi = "../api/2.0";
const urlApi = "api/2.0"; // ../api/2.0
const storageUrl = 'storage';
const baseName = "";
const useFullApi = false;
const apiEndpointMapping = {
  '/': { module: "home", call: 'get-content', callParam: '' },
  '/Article': { module: "article", call: 'get-content', callParam: 'get-content-by-slug' },
  '/ArticleView': { module: "article", call: 'get-content', callParam: 'get-content-by-slug' },
};

export { urlApi, useFullApi, storageUrl, baseName, apiEndpointMapping }