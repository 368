import React from 'react';
import { urlApi } from '../Constants/Global';

import SectionCarousel from '../Components/SectionCarousel';
import SectionContact from '../Components/SectionContact';
import Bg from '../Medias/Photos/photo-1.jpg';
import SectionPractice from '../Components/SectionPractice';
import SectionVision from '../Components/SectionVision';
import SectionNews from '../Components/SectionNews';
import SectionPartner from '../Components/SectionPartner';
import SectionAbout from '../Components/SectionAbout';
import SectionService from '../Components/SectionService';
import SectionTestimonial from '../Components/SectionTestimonial';
import SectionTeam from '../Components/SectionTeam';
import SectionPublication from '../Components/SectionPublication';
import SectionClientCarousel from '../Components/SectionClientCarousel';

const Home = ({ contents }) => {
  console.log(contents)
  const storage = urlApi + '/storage/article/';

  const slides = [
    {
      row_value: Bg,
      detail:
        <>
          <h1 className='tx-color-secondary font-header fw-normal mb-0' style={{ lineHeight: 1.1 }}>Pioneering</h1>
          <h1 className='tx-color-primary font-header fw-normal' style={{ lineHeight: 1.1 }}>Global Legal<br />Excellence</h1>
          {/* <p className='tx-color-primary font-default'>ABER is the FIRST law firm legally accepting cryptocurrencies in Thailand.</p> */}
        </>
    }
  ];

  return (
    <>
      {/* <ContentDisplay contents={contents?.content?.contents} storage={storage} lang={contents.lang} /> */}
      <SectionCarousel contents={slides} />
      <SectionAbout contents={contents?.web_setup?.about} />
      <SectionService contents={contents?.web_setup?.services} />
      <SectionClientCarousel contents={contents?.web_setup?.client_logo} />
      {/* <SectionTestimonial /> */}
      <SectionTeam />
      <SectionPublication contents={contents?.web_setup?.publication} />
      <SectionNews contents={contents?.articles} />
      <SectionContact contents={contents} />
    </>
  )
}

Home.defaultProps = {
  contents: {
    lang: 'th',
    content: {
      contents: []
    }
  },
  web_setup: {
    practice_topic: [],
    practice: []
  },
  path: '',
  lang: 'en'
}

export default Home