import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import Logo1 from '../Medias/Photos/1649439336_0.png';
import Logo2 from '../Medias/Photos/1649439336_4.png';
import Logo3 from '../Medias/Photos/1649439336_1.png';
import Logo4 from '../Medias/Photos/1649439336_2.png';
import Logo5 from '../Medias/Photos/1654160149_0.png';
import Logo6 from '../Medias/Photos/1654160162_0.png';
import Logo7 from '../Medias/Photos/1701186441_0.png';

const SectionPartner = ({ contents }) => {
  const mockup = [
    { set1: Logo1 }, 
    { set1: Logo2 }, 
    { set1: Logo3 }, 
    { set1: Logo4 }, 
    { set1: Logo5 }, 
    { set1: Logo6 }, 
    { set1: Logo7 }, 
  ];

  return (
    <>
      <section id="client">
        <Container className='mt-5 mb-4 pt-5'>
          <h1 className='font-header text-center'>Our Clients</h1>
          <Row>
            <Col className="p-5 pb-0 px-0 px-md-5 text-center" sm={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }}>
              {mockup.map((item, index) => (
                <img src={item.set1} alt="Partner" className='mx-3' style={{ maxWidth: '140px' }} key={index} />
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

SectionPartner.defaultProps = {
  contents: []
}

export default SectionPartner