import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import NoPhoto from '../Medias/LibImages/img-placeholder-bk.png';
import Photo1 from '../Medias/Photos/1649677030_0.jpg'
import Photo2 from '../Medias/Photos/1668062467_0.jpg'
import Photo3 from '../Medias/Photos/messageImage_1722580737887.jpg'
import Photo4 from '../Medias/Photos/1708541406_0.jpg'

const SectionTeam = ({ contents }) => {
  const mockup = [
    {
      name: 'Dr. Tanatthep Tianprasit',
      position: 'Senior Partner',
      email: '',
      profile: <>Dr. Tanatthep Tianprasit obtained his LLB. from Faculty of Law, Thammasat University in 2000, LL.M. in Criminal Law and Criminal Justice from Faculty of Chulalongkorn University in 2006. LL.M. in Intellectual Property Law, Queen Mary, University of London, United Kingdom in 2008, and Ph.D. in Criminal Law and Criminal Justice from School of Law, University of Aberdeen in 2018. He holds a lawyer license and is specialized in criminal law and cybercrime.</>,
      row_value: Photo1
    },
    {
      name: 'Miss Siraporn Ouifak',
      position: 'IP Consultant',
      email: '',
      profile: <>Miss Siraporn Ouifak obtained her B.Sc. from the Faculty of Science (Major in Chemistry), Srinakharinwirot University in 2015 and an LL.B. from Faculty of Law, Chulalongkorn University in 2022. She is specialized in intellectual property, corporate services, provide legal advice relating to intellectual property matters. She has been handling all matters related to patents including analyzing claims relating to unprotected matters of the Patent Act., conducting IP searches in pharmaceutical field, filling new application of patent/petty patent/design as well as analyzing office actions on trademarks, and copy right. She has extensive experience in directly dealing with government agencies, such as Department of Intellectual Property (DIP), Department of Business Development (DBD) and the Ministry of Health.</>,
      row_value: Photo2
    },
    {
      name: 'Nontnawat Wattananont',
      position: 'Associate',
      email: '',
      profile: <>Nontnawat Wattananont received his LL.B. from Assumption University in 2020 and an MBA with a focus on Entrepreneurship from the University of the Thai Chamber of Commerce in 2024. As a lawyer, he has significant experience in providing consultancy in areas such as commercial law, corporate law, personal data protection, and labor law. He is proficient in drafting, reviewing, and negotiating commercial contracts, including power purchase agreements and public-private partnerships (PPP). Additionally, he has conducted research and developed legal recommendations for circular economy promotion projects aimed at intergovernmental organizations.</>,
      row_value: Photo3
    },
    // {
    //   name: 'Miss Waralee',
    //   position: 'Legal Counsel',
    //   email: '',
    //   profile: <>Miss Waralee as a legal counsel with five years of experience who graduated from Faculty of Law, Khonkaen university. She is specialized in alternative dispute resolution, commercial and administrative law. Expertise in legal advising, legal research and negotiation. She meticulously navigated complex disputes, for construction, Infrastructures construction ensuring adherence to legal procedures and offering solutions including development of sound strategies for successful resolution.</>,
    //   row_value: Photo4
    // },
  ];

  const [showModal, setShowModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedName, setSelectedName] = useState('');

  const handleViewProfile = (name, profile) => {
    setSelectedName(name);
    setSelectedProfile(profile);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProfile(null);
    setSelectedName('');
  };

  return (
    <>
      <section id="team" className='pt-5 pb-5 bg-color-primary'>
        <Container className='pt-5 pb-5'>
          <h1 className="font-header tx-color-secondary text-center mb-5">Team</h1>
          <Row>
            {mockup.map((item, index) => (
              <Col md={3} key={index}>
                <Card className='rounded-0 border-0 mb-4'>
                  <Card.Img className='ratio-1x1 bg-img bg-white rounded-0' style={{ backgroundImage: `url(${item.row_value})` }} />
                  <Card.Body>
                    <h5 className='tx-color-secondary text-uppercase'>{item.name}</h5>
                    <h6 className='mt-4 mb-4'>{item.position}</h6>
                    {item.email !== '' ?
                      <div>
                        <MdOutlineKeyboardArrowRight /> <a href="#" className='tx-color-primary text-decoration-none'>Email</a>
                      </div>
                      : ''}
                    <div>
                      <MdOutlineKeyboardArrowRight />{' '}
                      <button
                        className='btn btn-link tx-color-primary text-decoration-none p-0'
                        onClick={() => handleViewProfile(item.name, item.profile)}
                      >
                        View Profile
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Modal for Profile */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header className='px-4' closeButton>
          <Modal.Title>{selectedName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>{selectedProfile}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

SectionTeam.defaultProps = {
  contents: []
};

export default SectionTeam;
